import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes, { node } from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";
import { Rate, Input, Tabs, notification } from "antd";
import { Breadcrumb } from "antd";
import "antd/dist/antd.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import FeaturePrducts from "./../components/featureProducts";
// import { Doughnut } from "react-chartjs-2";
import { Modal, Button } from "antd";
import axios from "axios";
import SweetAlert from "sweetalert-react";
import Slider from "react-slick";
// import DonutChart from "react-svg-donut-chart"
import DonutChart from "react-donut-chart";

import { Link, navigate } from "gatsby";
// Wrap your app in th Cart Provider
// import { CartProvider, useCart } from "use-cart";
require("sweetalert/dist/sweetalert.css");
require("../css/product-landing.css");
require("../css/slick.css");
require("../css/slick-theme.css");

var images = [];
const TabPane = Tabs.TabPane;
var caluculateFarmerPer;
// var cartLength = 1;
// var dat;
// const Item = data => {
//   const { addItem, lineItemsCount } = useCart();

//   dat = lineItemsCount;
//   console.log("productaaa", lineItemsCount);
//   window.localStorage.setItem("countItem", lineItemsCount);
//   return (

//   );
// };

var len,
  dataCatId = [],
  filteredArray = [];

//var quantityValue = 1
class NativeProductsTemplate extends Component {
  constructor(props) {
    super(props);
    // if (window.localStorage.getItem("cartDetails") == null) {
    //   window.localStorage.setItem("cartDetails", "[]");
    // } else {
    //   var initialData = window.localStorage.getItem("cartDetails");
    //   var parse = JSON.parse(initialData);
    //   var len = parse.length;
    // }
    this.state = {
      showDiv: true,
      loading: false,
      visible: false,
      showAlert: false,
      quantityValue: 1,
      cartLength: 0,
      filteredArrayState: [],
      Incart: "",
      disabled: false,
    };
    this.incrementValue = this.incrementValue.bind(this);
    this.decrementValue = this.decrementValue.bind(this);
  }
  componentDidMount() {
    images.length = 0;
    images.length = 0;
    console.log(
      "one",
      this.props.data.wordpressWpNativeproduct.acf.product_image
    );
    console.log(
      "one",
      this.props.data.wordpressWpNativeproduct.acf.product_image[0].image
    );
    console.log("two", this.props.data.wordpressWpNativeproduct.acf);

    if (this.props.data.wordpressWpNativeproduct.acf.product_image !== null) {
      this.props.data.wordpressWpNativeproduct.acf.product_image.map((img) => {
        if (img.image == null) {
          var imageObj = {
            original:
              "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/hs250.jpg",
            thumbnail:
              "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/hs250.jpg",
            originalClass: "NC-slider-images",
            thumbnailClass: "NC-slider-thumbnail",
          };
          images.push(imageObj);
        } else {
          var imageObj = {
            original: img.image.source_url,
            thumbnail: img.image.source_url,
            originalClass: "NC-slider-images",
            thumbnailClass: "NC-slider-thumbnail",
          };
          images.push(imageObj);
        }
      });
    }

    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      var data = this.props.data.wordpressWpNativeproduct.id;
      dataCatId = this.props.data.wordpressWpNativeproduct.product_categories;
      // console.log("dataCatId", dataCatId);
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);
      this.setState({ cartData: parse });
      len = parse.length;
      this.setState({ cartLength: len });
      parse.map((product, index) => {
        if (product.id == data) {
          this.setState({ quantityValue: product.quantity });
        }
      });
    }

    var allWordpressWpNativeproduct = this.props.data
      .allWordpressWpNativeproduct.edges;

    dataCatId.forEach(function (element1) {
      allWordpressWpNativeproduct.forEach(function (element) {
        // console.log(
        //   "element.node.product_categories",
        //   element.node.product_categories
        // );
        if (
          element.node.product_categories.includes(element1) == true &&
          element.node.id != data
        ) {
          if (filteredArray.includes(element) == false) {
            filteredArray.push(element);
          }
        }
      });
    });

    this.setState({ filteredArrayState: filteredArray });
    filteredArray = [];
  }

  renderHighlights() {
    const post = this.props.data.wordpressWpNativeproduct;
    // console.log(this.props.data, "post1");
    return (
      <div>
        {post.acf &&
          post.acf.product_details.map((product) => (
            <p className="NC-highlight-text">
              <span className="NC-product-name">
                {product.product_name} ({product.quantity}):{" "}
              </span>
              {product.description}
            </p>
          ))}
      </div>
    );
  }

  renderIngredients() {
    const post = this.props.data.wordpressWpNativeproduct;
    return (
      <div>
        {post.acf &&
          post.acf.product_details.map((product) => (
            <p className="NC-highlight-text">
              <span className="NC-product-name">
                {product.product_name} ({product.quantity}):{" "}
              </span>{" "}
              {product.ingredients}
            </p>
          ))}
      </div>
    );
  }

  renderOrigin() {
    const post = this.props.data.wordpressWpNativeproduct;
    return (
      <div className="row">
        <div className="col-xs-12 col-md-3 col-sm-12 padd0 alignNC-karnataka-map">
          <img
            className="NC-karnataka-map"
            src="https://res.cloudinary.com/djoztpm50/image/upload/v1551969052/Native%20Circle/karnataka-png-1.jpg"
          />
        </div>
        <div className="col-xs-12 col-md-9 col-sm-12 padd0">
          {post.acf &&
            post.acf.product_details.map((product) => (
              <div>
                <p className="NC-product-origin">
                  <span className="NC-product-name">
                    {product.product_name} ({product.quantity}):{" "}
                  </span>
                  Sourced from - {product.origin}
                </p>
                <p className="NC-farmers-paragraph">
                  {product.origin_description}
                </p>
              </div>
            ))}
        </div>
      </div>
    );
  }
  changeIcon() {
    this.setState({ showDiv: true });
  }

  // render() {
  //     const post = this.props.data.wordpressWpNativeproduct

  //     return (
  //         <div>
  //             <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
  //             <div dangerouslySetInnerHTML={{ __html: post.content }} />
  //             <div dangerouslySetInnerHTML={{ __html: post.acf.product_title }} />

  //         </div>
  //     )
  // }

  collapseIcon() {
    this.setState({ showDiv: false });
  }
  expandIcon() {
    this.setState({ showDiv: true });
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  handleOk() {
    var _this = this;
    _this.setState({ loading: true });
    let name = document.getElementById("name").value;
    let number = document.getElementById("number").value;
    let email = document.getElementById("email").value;
    var bodyFormData = new FormData();
    bodyFormData.set("fullName", document.getElementById("name").value);
    bodyFormData.set("email", document.getElementById("email").value);
    bodyFormData.set("number", document.getElementById("number").value);
    bodyFormData.set("quantity", document.getElementById("quantity").value);
    bodyFormData.set("formID", 192);

    axios
      .post(
        "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/192/feedback",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setTimeout(() => {
          _this.setState({ loading: false, visible: false });
          document.getElementById("form").reset();
        }, 3000);
        if (response.status == 200 || response.status == 201) {
          _this.setState({
            showAlert: true,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  handleCancel = () => {
    this.setState({ visible: false });
  };

  incrementValue() {
    let currentvalue = document.getElementById("productQuantity").value;

    var incrementedValue = ++currentvalue;
    if (incrementedValue > 10) {
      navigate("/business/");
      // console.log(incrementedValue);
      // document.getElementById("productQuantity").value = incrementedValue;
      //  quantityValue=incrementedValue
    } else this.setState({ quantityValue: incrementedValue, disabled: false });
  }

  decrementValue() {
    let currentvalue = document.getElementById("productQuantity").value;
    if (currentvalue > 1) {
      var decrementedValue = --currentvalue;
      // console.log(decrementedValue);
      document.getElementById("productQuantity").value = decrementedValue;
    }
    // quantityValue= decrementedValue
    if (decrementedValue == undefined) {
      decrementedValue = 1;
    }
    this.setState({ quantityValue: decrementedValue, disabled: false });
  }

  addToCart() {
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      const post = this.props.data.wordpressWpNativeproduct;
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);
      // console.log("before", post.id);

      // console.log(arrObj);
      this.setState({ Incart: "Added to cart", disabled: true }, () => {
        this.notificationStatus("success", this.state.Incart);
      });
      if (parse.length != 0) {
        var arrObj = {};
        arrObj["id"] = post.id;
        arrObj["quantity"] = this.state.quantityValue;
        parse.push(arrObj);
        // console.log("parse", parse);

        var nodup = parse.filter(
          (elem, index, self) =>
            self.findIndex((obj) => {
              return obj.id === elem.id;
            }) === index
        );

        nodup.map((e, index) => {
          if (e.id == arrObj["id"]) {
            nodup[index].quantity = arrObj["quantity"];
          }
        });
        // console.log("already in cart", nodup);
        // console.log("quant", arrObj["quantity"]);
        // if (arrObj["quantity"] != arrObj["quantity"]) {
        //   console.log("quant", arrObj["quantity"]);
        //   this.setState({ Incart: "Added to cart" });
        // }

        window.localStorage.setItem("cartDetails", JSON.stringify(nodup));
        var len = nodup.length;
        // console.log("lenlen", len);
        this.setState({ cartLength: len });

        // var nodup = parse.filter(
        //   (elem, index, self) =>
        //     self.findIndex(obj => {
        //       return obj.name === elem.name;
        //     }) === index
        // );
      } else {
        var arr = [];
        var arrObj = {};
        arrObj["id"] = post.id;
        arrObj["quantity"] = this.state.quantityValue;
        arr.push(arrObj);
        window.localStorage.setItem("cartDetails", JSON.stringify(arr));
        var len = arr.length;
        this.setState({ cartLength: len });
      }
    }
  }

  notificationStatus(type, Incart) {
    notification[type]({
      message: `${Incart}`,
      duration: 1.3,
    });
  }

  buyNow() {
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      const post = this.props.data.wordpressWpNativeproduct;
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);
      // console.log("before", this.props);

      // console.log(arrObj);
      if (parse.length != 0) {
        var arrObj = {};
        arrObj["id"] = post.id;
        arrObj["quantity"] = this.state.quantityValue;
        parse.push(arrObj);
        // console.log("parse", parse);

        var nodup = parse.filter(
          (elem, index, self) =>
            self.findIndex((obj) => {
              return obj.id === elem.id;
            }) === index
        );

        nodup.map((e, index) => {
          if (e.id == arrObj["id"]) {
            nodup[index].quantity = arrObj["quantity"];
          }
        });
        window.localStorage.setItem("cartDetails", JSON.stringify(nodup));
        var len = nodup.length;
        // console.log("lenlen", len);
        this.setState({ cartLength: len });

        // var nodup = parse.filter(
        //   (elem, index, self) =>
        //     self.findIndex(obj => {
        //       return obj.name === elem.name;
        //     }) === index
        // );
      } else {
        var arr = [];
        var arrObj = {};
        arrObj["id"] = post.id;
        arrObj["quantity"] = this.state.quantityValue;
        arr.push(arrObj);
        window.localStorage.setItem("cartDetails", JSON.stringify(arr));
        var len = arr.length;
        this.setState({ cartLength: len });
      }
      this.props.navigate("/myCart");
    }
  }

  addQuantity(evt) {
    //console.log("value", evt.target.value)
    this.setState({ quantityValue: evt.target.value });
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
          },
        },
      ],
    };

    const post = this.props.data.wordpressWpNativeproduct;
    // console.log("post.acf.base_price", post.acf.base_price, typeof post.acf.base_price);
    var caluculateGstAmount;
    if (
      post &&
      post.acf &&
      post.acf.gst_percentage != 0 &&
      post.acf.base_price != ""
    ) {
      caluculateGstAmount =
        (parseFloat(post.acf.base_price) *
          parseFloat(post.acf.gst_percentage)) /
        100;

      // console.log("caluculateGstAmount", caluculateGstAmount, post.acf.gst_percentage, post.acf.base_price)
    } else {
      caluculateGstAmount = 0;
    }

    // calculate percentage for donut chart
    var farmerPt = post.acf
      ? (post.acf.pay_for_artisans / post.acf.price) * 100
      : 0;
    var rawMaterialPt = post.acf
      ? (post.acf.raw_materials / post.acf.price) * 100
      : 0;
    var impactPt = post.acf
      ? (post.acf.impact_partners / post.acf.price) * 100
      : 0;
    var fullfillmentPt = post.acf
      ? (post.acf.fulfillment / post.acf.price) * 100
      : 0;
    var nativePt = post.acf
      ? (post.acf.native_circle / post.acf.price) * 100
      : 0;

    const { visible, loading } = this.state;

    var exCaluculateFarmerPer = post.acf ? post.acf.price * 0.55 : 0;

    // caluculateFarmerPer = exCaluculateFarmerPer.toFixed(2);
    return (
      <div id="nativeProducts">
        <SweetAlert
          show={this.state.showAlert}
          title="Thank you for contacting us!"
          text="We will get in touch with you soon."
          onConfirm={() => this.setState({ showAlert: false })}
        />
        <Modal
          visible={visible}
          title="Get In Touch With Us"
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          footer={[
            <Button key="back" onClick={() => this.handleCancel()}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => this.handleOk()}
            >
              Submit
            </Button>,
          ]}
        >
          <form action="javascript:void(0)" id="form">
            <div className="row form-group">
              <div className="col-md-12 col-xs-12 col-sm-12 ">
                <label htmlFor="name" className="NC-formlabel">
                  Full name
                </label>
                <input
                  className="form-control NC-fullWidth"
                  type="text"
                  required
                  id="name"
                />
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="number" className="NC-formlabel">
                  Phone number
                </label>
                <input
                  className="form-control NC-fullWidth"
                  type="text"
                  pattern="[0-9\/]*"
                  required
                  id="number"
                />
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="email" className="NC-formlabel">
                  Email ID
                </label>
                <input
                  className="form-control NC-fullWidth"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  required
                  id="email"
                />
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="email" className="NC-formlabel">
                  Quantity
                </label>
                <input
                  className="form-control NC-fullWidth"
                  type="text"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  value={this.state.quantityValue}
                  defaultValue={this.state.quantityValue}
                  required
                  id="quantity"
                />
              </div>
            </div>
          </form>
        </Modal>
        <HeaderLight data={this.state.cartLength} />

        <div className="row NC-page-row">
          <div className="col-md-12 col-xs-12 col-sm-12 NC-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/">Home</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="/products/">Products</a>
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item>
                <a href="">Hair products</a>
              </Breadcrumb.Item> */}
              <Breadcrumb.Item>
                <span className="NC-currentBC">
                  {post.acf ? post.acf.product_title : ""}
                </span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="col-md-12 col-xs-12 col-sm-12">
            {/* <DonutChart data={dataPie} /> */}
          </div>
          <div className="col-md-12 col-xs-12 col-sm-12">
            <div className="row remdBtmSpace">
              <div className="col-md-5 col-xs-12 col-sm-6 pad30-left removepad img-gall-div">
                <ImageGallery
                  items={images}
                  autoPlay={false}
                  showPlayButton={false}
                  thumbnailPosition={"left"}
                  showThumbnails={true}
                  showFullscreenButton={false}
                  showNav={false}
                />
              </div>
              <div className="col-md-1 col-xs-hidden col-sm-hidden" />
              <div className="col-md-6 col-xs-12 col-sm-6 pad30-right removepad">
                <p className="NC-product-title NC-product-titleMob">
                  {post.acf ? post.acf.product_title : ""}
                </p>
                {/* <p className="NC-product-sub-title NC-product-sub-titleMob">
                  {post.acf.product_type}
                </p> */}
                {/*   <div className="NC-product-review">
                  <div className="NC-product-info">
                    <span className="NC-rating-number">4.0</span>
                    &nbsp;&nbsp;&nbsp;
                    <Rate allowHalf defaultValue={4} style={{ fontSize: 14 }} />
                  </div>
                  <div className="NC-product-info middle">
                    <span>320 Customers </span>
                  </div>
                  <div className="NC-product-info last">
                    <span className="underlined-text">Rate this product</span>
                  </div>
                </div> */}
                <div className="row NC-product-price-details NC-product-price-detailsMob">
                  <div className="col-xs-12 col-sm-8 col-md-9 pad0">
                    <p className="NC-product-price-title NC-product-price-titleMob">
                      Price -{" "}
                      <span> &#8377; {post.acf ? post.acf.price : 0} </span>
                      {/* <span className="NC-product-original-price">
                        MRP &#8377; 1699
                      </span> */}
                    </p>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-3 NC-align-right pad0">
                    {/* <span className="NC-product-discount-info">
                      Discount 30%
                    </span> */}
                  </div>
                </div>

                {post.acf &&
                  post.acf.pay_for_artisans != 0 &&
                  post.acf.raw_materials != 0 &&
                  post.acf.impact_partners != 0 &&
                  post.acf.native_circle != 0 && (
                    <hr className="NC-separator-fullWidth" />
                  )}

                {post.acf &&
                  post.acf.pay_for_artisans != 0 &&
                  post.acf.raw_materials != 0 &&
                  post.acf.impact_partners != 0 &&
                  post.acf.native_circle != 0 && (
                    <div className="row">
                      {/* <div className="col-xs-12 col-md-12 col-sm-12 pad0">
                    <div className="row NC-collapse-view">
                      <div className="col-xs-12 col-md-6 col-sm-12 NC-collapse-col">
                        <img
                          className="NC-collapse-img"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551860536/Native%20Circle/farmer.svg"
                        />
                        &nbsp;&nbsp;
                        <span className="NC-pay-para">
                          Pay for Farmers -{" "}
                          <span className="NC-pay-span">&#8377; 260</span> (40
                          %)
                        </span>
                      </div>
                      <div className="col-xs-12 col-md-6 col-sm-12 NC-text-center">
                        <span className="NC-pay-span">Months of work - 6 </span>
                        {this.state.showDiv ? (
                          <a
                            className="NC-collapse-icon"
                            onClick={() => this.collapseIcon()}
                          >
                            <img
                              className="NC-collapse-img"
                              src="https://res.cloudinary.com/djoztpm50/image/upload/v1551860745/Native%20Circle/group-44.svg"
                            />
                          </a>
                        ) : (
                            <a
                              className="NC-collapse-icon"
                              onClick={() => this.expandIcon()}
                            >
                              <img
                                className="NC-collapse-img"
                                src="https://res.cloudinary.com/djoztpm50/image/upload/v1552297841/Native%20Circle/group-129.svg"
                              />
                            </a>
                          )}
                      </div>
                    </div>
                  </div> */}

                      {this.state.showDiv ? (
                        <a
                          className="NC-collapse-icon"
                          onClick={() => this.collapseIcon()}
                        >
                          <div className="col-xs-12 col-md-12 col-sm-12 pad0">
                            <div className="row NC-collapse-view">
                              <div className="col-xs-12 col-md-6 col-sm-12 NC-collapse-col NC-collapse-colMob">
                                <img
                                  className="NC-collapse-img-left NC-collapse-img-leftMob"
                                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1551860536/Native%20Circle/farmer.svg"
                                />

                                <span className="NC-pay-span">
                                  Pay for Farmers -{" "}
                                  <span className="NC-pay-span">
                                    &#8377;{" "}
                                    {post.acf ? post.acf.pay_for_artisans : ""}
                                  </span>{" "}
                                  {/* (55 %) */}
                                </span>
                              </div>
                              <div className="col-xs-12 col-md-6 col-sm-12 NC-text-center NC-text-centerMob">
                                <span
                                  className="NC-pay-span"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {post.acf ? post.acf.typeOfProduct : ""}
                                </span>

                                <img
                                  className="NC-collapse-img-right"
                                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1551860745/Native%20Circle/group-44.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      ) : (
                        <a
                          className="NC-collapse-icon"
                          onClick={() => this.expandIcon()}
                        >
                          <div className="col-xs-12 col-md-12 col-sm-12 pad0">
                            <div className="row NC-collapse-view">
                              <div className="col-xs-12 col-md-6 col-sm-12 NC-collapse-col NC-collapse-colMob">
                                <img
                                  className="NC-collapse-img-left NC-collapse-img-leftMob"
                                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1551860536/Native%20Circle/farmer.svg"
                                />

                                <span className="NC-pay-para">
                                  Pay for Farmers -{" "}
                                  <span className="NC-pay-span">
                                    &#8377;{" "}
                                    {post.acf ? post.acf.pay_for_artisans : ""}
                                  </span>{" "}
                                  {/* (55 %) */}
                                </span>
                              </div>
                              <div className="col-xs-12 col-md-6 col-sm-12 NC-text-center NC-text-centerMob">
                                <span
                                  className="NC-pay-span"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {post.acf ? post.acf.typeOfProduct : ""}
                                </span>

                                <img
                                  className="NC-collapse-img-right"
                                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1552297841/Native%20Circle/group-129.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      )}

                      {this.state.showDiv ? (
                        <div className="col-xs-12 col-sm-12 col-md-12 pad0 NC-background">
                          <div className="row">
                            <div className="col-xs-12 col-md-6 col-sm-12 graph-div">
                              {/* <DonutChart data={dataPie} /> */}

                              <DonutChart
                                data={[
                                  {
                                    label: "Pay for farmers",
                                    value: farmerPt,

                                    className: "colorr",
                                    outerRadius: 0.7,
                                    innerRadius: 0.7,
                                    clickToggle: false,
                                  },
                                  {
                                    label: "Raw materials",
                                    value: rawMaterialPt,
                                    outerRadius: 0.7,
                                    className: "colorr",
                                    innerRadius: 0.7,
                                  },
                                  {
                                    label: "Impact partners",
                                    value: impactPt,
                                    outerRadius: 0.7,
                                    innerRadius: 0.7,
                                    className: "colorr",
                                  },
                                  {
                                    label: "Fulfillment",
                                    value: fullfillmentPt,
                                    outerRadius: 0.7,
                                    innerRadius: 0.7,
                                    className: "colorr",
                                  },
                                  {
                                    label: "Native circle",
                                    value: nativePt,
                                    outerRadius: 0.7,
                                    innerRadius: 0.7,
                                    className: "colorr",
                                  },
                                  {
                                    label: "GST",
                                    value: caluculateGstAmount,
                                    outerRadius: 0.7,
                                    innerRadius: 0.7,
                                    className: "colorr",
                                  },
                                ]}
                                colors={[
                                  "#a3996e",
                                  "#ccaa91",
                                  "#f1ba48",
                                  "#5e6369",
                                  "#491f0f",
                                  "#250c20",
                                  "#03a9f4",
                                  "#00bcd4",
                                  "#009688",
                                  "#4caf50",
                                  "#8bc34a",
                                  "#cddc39",
                                  "#ffeb3b",
                                  "#ffc107",
                                  "#ff9800",
                                  "#ff5722",
                                  "#795548",
                                  "#607d8b",
                                ]}
                                innerRadius={0.8}
                                outerRadius={0.95}
                                selectedOffset={0}
                                strokeColor="#ffffff"
                              />

                              {/* <div>
                            <figure>
                              <figcaption>
                                <div className=" totalWeek">
                                  TOTAL <br />
                                  &#8377; {post.acf ? post.acf.price : ''}
                                </div>
                              </figcaption>
                              <svg viewBox="0 0 63.6619772368 63.6619772368">
                                <circle
                                  className="pie1"
                                  cx="31.8309886184"
                                  cy="31.8309886184"
                                  r="15.9154943092"
                                />
                                <circle
                                  className="pie2"
                                  cx="31.8309886184"
                                  cy="31.8309886184"
                                  r="15.9154943092"
                                />
                                <circle
                                  className="pie3"
                                  cx="31.8309886184"
                                  cy="31.8309886184"
                                  r="15.9154943092"
                                />
                                <circle
                                  className="pie4"
                                  cx="31.8309886184"
                                  cy="31.8309886184"
                                  r="15.9154943092"
                                />
                                <circle
                                  className="pie5"
                                  cx="31.8309886184"
                                  cy="31.8309886184"
                                  r="15.9154943092"
                                />
                                <circle
                                  className="pie6"
                                  cx="31.8309886184"
                                  cy="31.8309886184"
                                  r="15.9154943092"
                                />
                              </svg>
                            </figure>
                          </div> */}
                            </div>
                            <div className="col-xs-12 col-md-6 col-sm-12">
                              <ul className="NC-chart-ul">
                                <li className="NC-chart-list1">
                                  <span className="NC-chart-span1">
                                    &#8377;&nbsp;
                                    {post.acf ? post.acf.pay_for_artisans : ""}
                                  </span>
                                  <span className="NC-chart-bold">
                                    {" "}
                                    - Pay for farmers
                                  </span>
                                  <br />
                                  <p className="NC-chart-para">
                                    Profit for artisan collective
                                  </p>
                                </li>
                                <li className="NC-chart-list2">
                                  <span className="NC-chart-span2">
                                    &#8377;&nbsp;
                                    {post.acf ? post.acf.raw_materials : ""}
                                  </span>
                                  <span className="NC-chart-bold">
                                    {" "}
                                    - Raw materials
                                  </span>
                                  <br />
                                  <p className="NC-chart-para">
                                    Cultivation procurement and processing
                                  </p>
                                </li>
                                <li className="NC-chart-list3">
                                  <span className="NC-chart-span3">
                                    &#8377;&nbsp;
                                    {post.acf ? post.acf.impact_partners : ""}
                                  </span>
                                  <span className="NC-chart-bold">
                                    {" "}
                                    - Impact partner
                                  </span>
                                  <br />
                                  <p className="NC-chart-para">
                                    Wholse sale retail online Training and Admin
                                  </p>
                                </li>
                                <li className="NC-chart-list4">
                                  <span className="NC-chart-span4">
                                    &#8377;&nbsp;
                                    {post.acf ? post.acf.fulfillment : ""}
                                  </span>
                                  <span className="NC-chart-bold">
                                    {" "}
                                    - Fulfillment
                                  </span>
                                  <br />
                                  <p className="NC-chart-para">
                                    Packaging, payment gateway & holding
                                    inventory
                                  </p>
                                </li>
                                <li className="NC-chart-list5">
                                  <span className="NC-chart-span5">
                                    &#8377;&nbsp;
                                    {post.acf ? post.acf.native_circle : ""}
                                  </span>
                                  <span className="NC-chart-bold">
                                    {" "}
                                    - Native Circle platform
                                  </span>
                                  <br />
                                  <p className="NC-chart-para">
                                    Product development and Fullfilment
                                  </p>
                                </li>
                                <li className="NC-chart-list6">
                                  <span className="NC-chart-span6">
                                    &#8377;&nbsp;
                                    {caluculateGstAmount.toFixed(2)}
                                  </span>
                                  <span className="NC-chart-bold"> - GST</span>
                                  <br />
                                  <p className="NC-chart-para">
                                    Goods & Services Tax India
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  )}
                <hr className="NC-separator-fullWidth" />

                <div className="row NC-buttons-row">
                  <span className="NC-product-unit">Unit</span>
                  <div
                    className="NC-product-circles"
                    onClick={this.decrementValue}
                  >
                    <img
                      className="pointer"
                      src="https://img.icons8.com/small/12/000000/expand-arrow.png"
                    />
                  </div>
                  <input
                    type="number"
                    value={this.state.quantityValue}
                    className="custom-text-number custom-text-numberMob"
                    id="productQuantity"
                    onChange={this.addQuantity.bind(this)}
                  />
                  <div
                    className="NC-product-circles"
                    onClick={this.incrementValue}
                  >
                    <img
                      className="pointer"
                      src="https://img.icons8.com/small/12/000000/collapse-arrow.png"
                    />
                  </div>
                  {/* <button className="addToCart addtocartCursor" href="">
                    Add to cart{" "}
                    <img
                      className="iconCheckout"
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1551181856/Native%20Circle/path.svg"
                    />
                  </button> */}
                  {/*    <button
                      className="buyNowButton"
                      onClick={() => addItem("hello")}
                    >
                      Add to cart{" "}
                      <img
                        className="iconCheckout"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button> */}
                  <button
                    className="buyNowButton buyNowButtonMob"
                    onClick={this.addToCart.bind(this)}
                    disabled={this.state.disabled}
                  >
                    Add to cart{" "}
                    <img
                      className="iconCheckout"
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                    />
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button
                    className="buyNowButton"
                    onClick={this.buyNow.bind(this)}
                  >
                    Buy now{" "}
                    <img
                      className="iconCheckout"
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                    />
                  </button>
                </div>

                <div className="row">
                  {/* <span className="NC-product-delivery">Delivery</span>
                  <div className="NC-product-pincode">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551868285/Native%20Circle/placeholder.svg" />
                    <input
                      className="pincode"
                      type="text"
                      defaultValue="500081"
                    />
                    <span className="NC-pin-change">Change</span>
                    <hr className="NC-separator-fullWidth-noMargin" />
                    <p className="NC-delivery-by">
                      Delivery in{" "}
                      <span className="NC-delivery-days">2 Days</span>,
                      Wednesday{" "}
                    </p>
                  </div> */}
                  <div className="col-md-12 col-xs-12 col-sm-12 padd0">
                    <Tabs
                      defaultActiveKey="1"
                      onChange={this.callback}
                      className="productLandingTab"
                    >
                      <TabPane tab="Product origin" key="1">
                        {/* {
                        post.acf
                      } */}
                        {this.renderOrigin()}
                      </TabPane>
                      <TabPane tab="Product ingredients" key="2">
                        {this.renderIngredients()}
                      </TabPane>
                      <TabPane tab="Product highlights" key="3">
                        {this.renderHighlights()}
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-12 col-sm-12">
            {this.state.filteredArrayState.length > 0 ? (
              <h3 className="NC-recommended-products">Recommended products</h3>
            ) : (
              ""
            )}
          </div>
          <div className="remdProSlider">
            <Slider {...settings}>
              {this.state.filteredArrayState.map(({ node }) => (
                <Link
                  to={`/nativeproduct/${node.slug}/`}
                  href={`/nativeproduct/${node.slug}/`}
                >
                  <div className="remdPropadding">
                    <div className="productDivisions">
                      {node.acf.discount != "" && (
                        <div className="prodDiscount">
                          <p className="prodDiscountPercentage">
                            -{node.acf.discount}%
                          </p>
                        </div>
                      )}
                      <a href="/nativeproduct/activated-charcoal-bath-soap">
                        <div className="productimg">
                          <img
                            src={
                              node.acf.image == null
                                ? "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/hs250.jpg"
                                : node.acf.image.source_url
                            }
                            className="img-fluid remdImg"
                            alt="Responsive image"
                          />
                          {/* <img src="https://dashboard.nativecircle.in/wp-content/uploads/2019/03/charcoalsoap-2-480x485.jpg" className=" img-fluid" alt="Responsive image" /> */}
                        </div>
                        <div className="alignPriceDiv">
                          {/* <div className="rating">
                            <div className="star">
                              <input name="stars" id="e5" type="radio" />
                              <label htmlFor="e5">☆</label>
                            </div>
                            <div className="star">
                              <input name="stars" id="e4" type="radio" />
                              <label htmlFor="e4">☆</label>
                            </div>
                            <div className="star">
                              <input name="stars" id="e3" type="radio" />
                              <label htmlFor="e3">☆</label>
                            </div>
                            <div className="star">
                              <input name="stars" id="e2" type="radio" />
                              <label htmlFor="e2">☆</label>
                            </div>
                            <div className="star">
                              <input name="stars" id="e1" type="radio" />
                              <label htmlFor="e1">☆</label>
                            </div>
                          </div> */}
                          <p className="fprodName">{node.title}</p>
                          {node.acf.product_details.length == 1 ? (
                            node.acf.product_details.map((product) => (
                              <p className="prodRupeesWithoutScratch">
                                <span className="proddRupeeSymbol">
                                  &#8377;
                                </span>
                                {node.acf.price}, {product.quantity}
                              </p>
                            ))
                          ) : (
                            <p className="prodRupeesWithoutScratch">
                              <span className="proddRupeeSymbol">&#8377;</span>
                              {node.acf.price}
                            </p>
                          )}
                        </div>
                      </a>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>

          {/* <FeaturePrducts /> */}
        </div>
        <div className="padding50">
          <Footer />
        </div>
      </div>
    );
  }
}

export default NativeProductsTemplate;

export const NativeProductsQuery = graphql`
  query currentNativeProductQuery($id: String!) {
    wordpressWpNativeproduct(id: { eq: $id }) {
      title
      id
    }
    allWordpressWpNativeproduct {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
